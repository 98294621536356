import React from 'react';
import { useParams } from 'react-router-dom';

import ContactForm from "../ContactForm/ContactForm";
import Spacing from '../Spacing';
import SectionHeading from '../SectionHeading';


const DynamicPage = () => {

  return (
    <div>
      <Spacing lg="120" md="50" />
      
      {/* Start About Section */}
      <section>
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-5">
		
              <ContactForm />
             
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="cs-height_0 cs-height_lg_40" />
              <SectionHeading
                title="Digital Solution"
                subtitle="Digital Agency"
                btnText="Learn More"
                btnLink="/about"
              >
                <Spacing lg="30" md="20" />
                <p>
                Welcome to Vishal Web Tech, your trusted partner in the digital world! As a premier digital agency, we pride ourselves on offering a wide array of services tailored to meet the unique needs of your business. Our passionate team of experts is dedicated to crafting innovative solutions that drive success and help your brand thrive online. <br /> <br />

Web Development: At Vishal Web Tech, we understand that your website is the cornerstone of your digital presence. Our skilled developers work tirelessly to create fast, responsive, and visually stunning websites that captivate your audience and enhance user engagement. Whether you need a simple landing page or a complex e-commerce platform, we tailor our strategies to align with your vision and business goals. <br /> <br />

Software Development: Stay ahead of the competition with our custom software development solutions. Our talented developers turn complex problems into scalable and efficient software applications. From desktop software to cloud-based systems, we cater to your specific requirements, ensuring seamless integration and optimal performance. Partner with us to transform your ideas into powerful, user-friendly software solutions. <br /> <br />

Digital Marketing: Vishal Web Tech provides cutting-edge digital marketing services designed to boost your online visibility and drive business growth. Our SEO experts employ best practices to optimize your website, ensuring it ranks higher in search engine results. Our PPC specialists create targeted ad campaigns that reach your audience and maximize ROI. We focus on delivering measurable results that increase traffic, conversions, and brand authority. <br /> <br />

Graphic Designing: Elevate your brand aesthetics with our exceptional graphic design services. Our creative designers bring your brand story to life with visually appealing designs that resonate with your audience. From logo design and brand identity to brochures and social media graphics, we craft designs that leave a lasting impression and set your brand apart. <br /> <br />

At Vishal Web Tech, our commitment to excellence and customer satisfaction drives everything we do. We work closely with you to understand your business needs and deliver customized solutions that empower your journey toward digital success. Explore our services and discover how we can help you achieve your goals. Let's create something incredible together! 

<br />

                </p>
              </SectionHeading>
            </div>
          </div>
        </div>
        <div className="cs-height_150 cs-height_lg_80" />
      </section>
      {/* End About Section */}
    </div>
  );
};

export default DynamicPage;
