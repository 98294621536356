import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

import TextWidget from '../Widget/TextWidget';


export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Graphic Designing'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Graphic Designing'
      />
      <Spacing lg='100' md='20'/>
      <Div className="container">
         {/* Start PortfolioSlider Section */}
     
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4">
          Best Digital Marketing Agency
        </h2>
        <Spacing lg="0" md="0" />
        <TextWidget
        text="In today's digital age, visual communication is more powerful than ever. At Vishal Web Tech, we understand the importance of creating compelling, original, and eye-catching designs that not only capture attention but also convey your brand's message effectively. Our team of expert graphic designers is dedicated to transforming your ideas into visually stunning realities."
        />
		<TextWidget
        text="Logo Design:
A unique logo serves as the foundation of your brand identity. Our team crafts logos that reflect your business values and resonate with your target audience."
        />
		<TextWidget
        text="Branding and Identity:
Consistent and cohesive branding is key to building trust. We offer comprehensive branding solutions, including business cards, letterheads, and promotional materials, ensuring your brand stands out."
        />
		<TextWidget
        text="Web Graphics:
Enhance your online presence with custom illustrations, banners, and social media graphics that drive engagement and interaction."
        />
		<TextWidget
        text="Brochure and Flyer Design:
Need to promote a service or event? Our designers create informative and visually appealing brochures and flyers to captivate potential clients."
        />
		
		<TextWidget
        text="Packaging Design:
Make your products shine on the shelves with our creative and practical packaging design solutions that highlight your product's unique features."
        />
		<TextWidget
        text="Infographics:
Simplify complex information with our easy-to-understand and appealing infographics that make an impact."
        />
		
		<TextWidget
        text="Custom Illustrations:
Whether for print or digital platforms, our custom illustrations add a personal and artistic touch to your projects."
        />
		
      </Div>
      <Spacing lg="90" md="70" />
	  <section id= "portfolioslider" >
     
	   </section>
      {/* End PortfolioSlider Section */}
        <Spacing lg='90' md='45'/>
      </Div>
     
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
