import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

import TextWidget from '../Widget/TextWidget';


export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Software Development'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Software Development'
      />
      <Spacing lg='100' md='20'/>
      <Div className="container">
         {/* Start PortfolioSlider Section */}
     
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4">
          Best Digital Marketing Agency
        </h2>
        <Spacing lg="0" md="0" />
        <TextWidget
        text="Welcome to Vishal Web Tech, where innovation meets excellence in software development. Our agency is dedicated to transforming your ideas into dynamic digital solutions. With a passion for technology and a commitment to delivering quality, we partner with businesses to enhance their digital presence and operational efficiency."
        />
		<TextWidget
        text="Custom Software Development:
Tailored to your specific needs, we create scalable and robust software solutions. Whether you require a unique application, a revamped legacy system, or a complex enterprise solution, we ensure it aligns perfectly with your business objectives."
        />
		<TextWidget
        text="Web Application Development:
We specialize in building interactive and secure web applications that captivate users and streamline business processes. Our responsive designs ensure seamless experiences across all devices."
        />
		<TextWidget
        text="Mobile App Development:
Our team develops intuitive and engaging mobile apps for both iOS and Android platforms. We focus on user-centric designs and functionalities that drive engagement and brand loyalty."
        />
		<TextWidget
        text="Cloud Solutions:
Embrace the power of the cloud with our comprehensive services. From cloud migration to devops and tangible cloud infrastructures, our solutions enhance flexibility, efficiency, and scalability."
        />
		<TextWidget
        text="E-Commerce Solutions:
Grow your business online with our comprehensive e-commerce platforms. We provide end-to-end solutions that encompass everything from shopping cart integration to payment gateway setup, ensuring a smooth user journey."
        />
		<TextWidget
        text="UI/UX Design:
Our design team crafts visually compelling and intuitive interfaces. By understanding user behavior and preferences, we create designs that enhance usability and leave a lasting impression."
        />
		<TextWidget
        text="Software Maintenance and Support:
Post-launch, we ensure your software remains reliable and up-to-date with our dedicated maintenance and support services. Get 24/7 assistance and regular updates to guarantee optimum performance."
        />
      </Div>
      <Spacing lg="90" md="70" />
	  <section id= "portfolioslider" >
     
	   </section>
      {/* End PortfolioSlider Section */}
        <Spacing lg='90' md='45'/>
      </Div>
     
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
