// src/components/ImageSliderWithZoom.js

import React, { useRef, useState, useEffect } from 'react';
import './ImageSlider.scss';

const images = [
  { id: 1, src: '/images/client/wonder-play-toys.png', alt: 'Image 1', width: 600, height: 400 },
  { id: 2, src: '/images/client/escapetravelservices.png', alt: 'Image 2', width: 600, height: 400 },
  { id: 3, src: '/images/client/bag-pack-vacation.png', alt: 'Image 3', width: 600, height: 400 },
  { id: 4, src: '/images/client/pscaluminium.png', alt: 'Image 4', width: 600, height: 400 },
  { id: 5, src: '/images/client/tej-overseas-logistic.png', alt: 'Image 5', width: 600, height: 400 },
  { id: 6, src: '/images/client/hpr-steel.png', alt: 'Image 6', width: 600, height: 400 },
  { id: 7, src: '/images/client/middle-east.png', alt: 'Image 7', width: 600, height: 400 },
  { id: 8, src: '/images/client/sarkariiresultinfo.png', alt: 'Image 8', width: 600, height: 400 },
  { id: 9, src: '/images/client/the-dubai-today.png', alt: 'Image 9', width: 600, height: 400 },
  { id: 10, src: '/images/client/anieartin.png', alt: 'Image 10', width: 600, height: 400 },
];

const ImageSliderWithZoom = () => {
  const sliderRef = useRef(null);
  const [zoomedImage, setZoomedImage] = useState(null);

  // Automatic scrolling interval
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });
      }
    }, 3000); // Scroll every 3 seconds

    // Clear interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const scrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  const handleImageClick = (src) => {
    setZoomedImage(src);
  };

  const closeZoomedImage = () => {
    setZoomedImage(null);
  };

  return (
  
    <div className="slider-container">
	
      <button className="slider-button left" onClick={scrollLeft}>
        &#10094; {/* Left Arrow */}
      </button>
      <div className="slider" ref={sliderRef}>
        {images.map((image) => (
          <div key={image.id} className="slide">
            <img
              src={image.src}
              alt={image.alt}
              className="slider-image"
              width={image.width}
              height={image.height}
              onClick={() => handleImageClick(image.src)} // Click to zoom
            />
          </div>
        ))}
      </div>
      <button className="slider-button right" onClick={scrollRight}>
        &#10095; {/* Right Arrow */}
      </button>

      {zoomedImage && (
        <div className="zoomed-image-container" onClick={closeZoomedImage}>
          <img src={zoomedImage} alt="Zoomed" className="zoomed-image" />
        </div>
      )}
	 
    </div>
	
  );
};

export default ImageSliderWithZoom;
