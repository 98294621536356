import React, { useState } from "react";


const ContactFormFooter = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: ""
  });

  const [status, setStatus] = useState(""); // To display the form submission status

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      // Submit form data to your server's PHP or API endpoint
      const response = await fetch('https://vishalwebtech.in/sendEmail.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setStatus('Message sent successfully!');
      } else {
        setStatus('Error sending message. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('Error sending message. Please try again.');
    }
    
    // Clear form data
    setFormData({
      name: "",
      mobile: "",
      email: ""
    });
  };

  return (
    <div className="contact-form-footer">
      <form onSubmit={handleSubmit}>
        <div>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            type="tel"
            name="mobile"
            placeholder="Mobile Number"
            value={formData.mobile}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>

      {status && <p>{status}</p>} {/* Display form submission status */}
    </div>
  );
};

export default ContactFormFooter;
