import React, { useEffect } from 'react';
import Card from '../Card';
import Hero from '../Hero';
import Hero5 from '../Hero/Hero5';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import PortfolioSlider from '../Slider/FullScreenVerticalSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';

import ImageSlider from '../ImageSlider/ImageSlider';

import TestimonialSliderStyle3 from '../Slider/TestimonialSliderStyle3';

import PortfolioSlider2 from '../Slider/PortfolioSlider2';
import TextWidget from '../Widget/TextWidget';


import ContactForm from "../ContactForm/ContactForm";
// Hero Social Links
const heroSocialLinks = [
  {
    name: 'Instagram',
    links: 'https://www.instagram.com/vishalwebtechdelhi/',
  },
  {
    name: 'Linkedin',
    links: 'https://in.linkedin.com/company/vishal-web-tech/',
  },
  {
    name: 'Facebook',
    links: 'https://www.facebook.com/vishalwebtech/',
  },
  
];

// FunFact Data



export default function Home() {
  pageTitle('Home');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
	
	{/* Start Hero Section */}
      <Hero5
        title="Grow Your Business<br /> With Digital Strategy"
        subtitle="We offer professional website designing services and digital marketing services. Whether you are looking for an e-commerce site or want to get your business online."
        btnLink="contact"
        btnText="Let’s talk"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
      />
      {/* End Hero Section */}
	   <Spacing lg="120" md="50" />
      

     
{/* Start About Section */}
      <section>
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-5">
		
              <ContactForm />
             
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="cs-height_0 cs-height_lg_40" />
              <SectionHeading
                title="Vishal Web Tech As Your Digital Marketing Agency?"
                subtitle="Why Choose Us"
                btnText="Learn More"
                btnLink="/about"
              >
                <Spacing lg="30" md="20" />
                <p>
                We are a leading digital marketing company that specializes in helping our clients increase their visibility online. 
                Our team is passionate about creating exceptional results for our customers, 
                and we never stop improving the way we offer services to them. If you're looking 
                for an experienced partner who will deliver high-quality work each time, then 
                Vishal Web Tech is the right choice!<br />
                  <br />
                  We help you gain more visibility and attract the right type of customers.<br />
                  <br />
                  Working with us, you will get access to a team who is skilled in SEO and PPC 
                  marketing so your site can succeed on major search engines like Google!
                </p>
              </SectionHeading>
            </div>
          </div>
        </div>
        <div className="cs-height_150 cs-height_lg_80" />
      </section>
      {/* End About Section */}

      {/* Start Servide Section */}
      <div className="cs-gradient_1">
        <Spacing lg="150" md="80" />
        <Div className="cs-shape_wrap_4">
          <Div className="cs-shape_4"></Div>
          <Div className="cs-shape_4"></Div>
          <Div className="container">
            <Div className="row">
              <Div className="col-xl-4">
                <SectionHeading
                  title="Empowering Businesses With Our Services"
                  subtitle=""
                />
                <Spacing lg="90" md="45" />
              </Div>
              <Div className="col-xl-8">
                <Div className="row">
                  <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Digital Marketing Services"
                      link="/services/digital-marketing-services"
                      src="/images/service_1.jpeg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Website Designing & Development"
                      link="/services/website-designing-development"
                      src="/images/service_2.jpeg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Software Development"
                      link="/services/software-development"
                      src="/images/service_3.jpeg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                  <Div className="col-lg-3 col-sm-6">
                    <Card
                      title="Graphic Designing"
                      link="/services/graphic-designing"
                      src="/images/service_4.jpeg"
                      alt="Service"
                    />
                    <Spacing lg="0" md="30" />
                  </Div>
                  
                  
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
        <Spacing lg="150" md="80" />
      </div>
      {/* End Servide Section */}

 {/* Start PortfolioSlider Section */}
      <Spacing lg="120" md="50" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4">
          Why We Are the Best Digital Marketing Agency in Delhi?
        </h2>
        <Spacing lg="0" md="0" />
        <TextWidget
        text="When you want to find the best digital marketing agency in Delhi, it can be difficult. 
        There are so many agencies out there that offer similar services. What makes us the best? 
        Do we have more experience than other agencies? Are our prices better? We pride ourselves on 
        being able to provide a wide variety of services for our clients. We are not just good at SEO and 
        PPC campaigns, but also have experience with web design, social media strategies, email marketing 
        campaigns and much more! With such an expansive background, we know how to create successful digital 
        marketing campaigns that will help your business grow!"
        />
      </Div>
      <Spacing lg="90" md="70" />
	  <section id= "portfolioslider" >
      <ImageSlider />
	   </section>
      {/* End PortfolioSlider Section */}
	  
	  

      {/* Start Testimonial Section */}
      <section>
        <div className="cs-height_145 cs-height_lg_75" />
        <div className="container">
          <SectionHeading
            title="Customer say something ( Google Reviews)"
            subtitle="Clients Feedback"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
          <TestimonialSliderStyle3 />
        </div>
        <Spacing lg="150" md="80" />
		
      </section>
      {/* End Testimonial Section */}


      {/* Start CTA Section */}
	  
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
		  
          btnLink="/contact"
          bgSrc="/images/cta_bg_3.jpeg"
        />
		
      </Div>
	  
      {/* End CTA Section */}
     

      

    </>
  );
}
