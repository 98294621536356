import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';

export default function AboutPage() {
  pageTitle('About');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          
            <SectionHeading
              title="Your trusted partner for business"
              subtitle="About Our Agency"
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Vishal Web Tech is a leading web design company in Delhi. We offer professional website designing services
               and digital marketing services. Whether you are looking for an e-commerce site or want to get 
               your business online, we can help you with that. We not only build websites but also provide 
               SEO solutions which will ensure that your website ranks well on the search engines like Google,
               Yahoo etc., thereby making it easier for people to find you. You can always rely on us because 
               we believe in quality and work hard to deliver projects within committed timelines. Vishal Web 
               Tech is a leading web designing company in Delhi. We have been providing professional website 
               designing services to clients from all over the world for past 4 years. For us, quality is of 
               utmost importance and we work hard towards delivering projects within committed time-frame while
               taking care of your requirements and needs.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          
          
        </Div>
     
      <Spacing lg="75" md="55" />
      {/* End About Section */}

      

      {/* Start About Section */}
      <section>
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-5">
              <img
                src="/images/about_img_9.jpg"
                alt="About"
                className="w-100 cs-radius_5"
              />
            </div>
            <div className="col-lg-6 offset-lg-1">
              <div className="cs-height_0 cs-height_lg_40" />
              <SectionHeading
                title="Vishal Web Tech As Your Digital Marketing Agency?"
                subtitle="Why Choose Us"
                btnText="Learn More"
                btnLink="/about"
              >
                <Spacing lg="30" md="20" />
                <p>
                We are a leading digital marketing company that specializes in helping our clients increase their visibility online. 
                Our team is passionate about creating exceptional results for our customers, 
                and we never stop improving the way we offer services to them. If you're looking 
                for an experienced partner who will deliver high-quality work each time, then 
                Vishal Web Tech is the right choice!<br />
                  <br />
                  We help you gain more visibility and attract the right type of customers.<br />
                  <br />
                  Working with us, you will get access to a team who is skilled in SEO and PPC 
                  marketing so your site can succeed on major search engines like Google!
                </p>
              </SectionHeading>
            </div>
          </div>
        </div>
        <div className="cs-height_150 cs-height_lg_80" />
      </section>
      {/* End About Section */}

      {/* Start CTA Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Cta
          title="Let’s disscuse make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
