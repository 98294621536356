import React from 'react';
import Div from '../Div';
import Slider from 'react-slick';
import TestimonialStyle2 from '../Testimonial/TestimonialStyle2';
const testimonialData = [
  {
    avatarImgUrl: '/images/avatar22.png',
    testimonialText:
      'I recently used a Vishal web tech website designing company and was thoroughly impressed. Their service was quick, professional, and reasonably priced. They communicated clearly with me and were able to meet all my requirements. ',
    avatarName: 'Rahul Pal',
    ratings: '5',
  },
  {
    avatarImgUrl: '/images/avatar-female.png',
    testimonialText:
      'Wonderful service received by Vishal Web Tech. Very happy with their service. They ask for your requirement and do the work perfectly and on time. I would recommend this place to everyone.',
    avatarName: 'Nandhini',
    ratings: '5',
  },
  {
    avatarImgUrl: '/images/avatar22.png',
    testimonialText:
      'Such a good designing company. Cost effective and highly professional staff. Understanding the clients basics and using the best experience to outperform. Highly recommended to all needy people.',
    avatarName: 'Harshal Dagade',
    ratings: '4',
  },
  {
    avatarImgUrl: '/images/avatar_5.png',
    testimonialText:
      'I am thrilled to share my experience with poze software. Their AI solutions have truly revolutionized the way businesses operate. They have set a new standard in the industry. Highly recommended!',
    avatarName: 'Leonel Bolian',
    avatarDesignation: 'Ninja Shop',
    ratings: '4',
  },
];

export default function TestimonialSliderStyle3() {
  /** Slider Settings **/
  const settings = {
    infinite: true,
    slidesToShow: 3,
    speed: 500,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <Slider {...settings} className="cs-slider cs-gap-24">
      {testimonialData.map((item, index) => (
        <Div key={index}>
          <TestimonialStyle2 {...item} />
        </Div>
      ))}
    </Slider>
  );
}
