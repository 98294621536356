import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

import TextWidget from '../Widget/TextWidget';


export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Digital Marketing Services'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Digital Marketing Services'
      />
      <Spacing lg='100' md='20'/>
      <Div className="container">
         {/* Start PortfolioSlider Section */}
     
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4">
          Best Digital Marketing Agency
        </h2>
        <Spacing lg="0" md="0" />
        <TextWidget
        text="At Vishal Web Tech, we are committed to elevating your digital presence with our comprehensive suite of services. We are experts in catalyzing your business growth through a variety of online solutions tailored to meet your unique needs."
        />
		<TextWidget
        text="Search Engine Optimization (SEO):
Our SEO strategies are designed to improve your website’s visibility, attract more traffic, and ultimately drive conversions. By using cutting-edge techniques and comprehensive keyword research, we ensure your business ranks higher in search engine results. With Vishal Web Tech, watch your website transform into a powerful tool that connects you with your target audience."
        />
		<TextWidget
        text="Pay-Per-Click (PPC) Advertising:
Maximize your ROI with our expertly managed PPC campaigns. Our team at Vishal Web Tech will spearhead your paid advertising efforts, creating targeted ads that reach potential customers at the perfect moment. By carefully analyzing data and refining strategies, we ensure every dollar spent contributes directly to your business objectives."
        />
		<TextWidget
        text="Web Development:
Crafting visually stunning and user-friendly websites is our specialty. Whether you need a simple landing page or a complex e-commerce platform, our web development team at Vishal Web Tech is equipped to deliver results that align with your brand identity and business goals. We combine aesthetic design with functionality to create websites that captivate and convert."
        />
		<TextWidget
        text="Software Development:
In an ever-evolving technological landscape, custom software solutions can give you a competitive edge. At Vishal Web Tech, we develop tailored software that addresses your business challenges. Our developers are adept at creating innovative solutions that streamline operations, enhance productivity, and boost profitability."
        />
      </Div>
      <Spacing lg="90" md="70" />
	  <section id= "portfolioslider" >
     
	   </section>
      {/* End PortfolioSlider Section */}
        <Spacing lg='90' md='45'/>
      </Div>
     
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
