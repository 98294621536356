import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { pageTitle } from '../../helper'
import Accordion from '../Accordion'
import Button from '../Button'
import Cta from '../Cta'
import IconBox from '../IconBox'
import PageHeading from '../PageHeading'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'

import TextWidget from '../Widget/TextWidget';


export default function ServiceDetailsPage() {
  pageTitle('Service Details');
  const params = useParams();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <PageHeading 
        title='Web Design & Web Development'
        bgSrc='/images/service_hero_bg.jpeg'
        pageLinkText='Web Design & Web Development'
      />
      <Spacing lg='100' md='20'/>
      <Div className="container">
         {/* Start PortfolioSlider Section */}
     
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 cs-line_height_4">
          Best Digital Marketing Agency
        </h2>
        <Spacing lg="0" md="0" />
        <TextWidget
        text="Welcome to Vishal Web Tech, where innovation meets functionality to create exceptional web experiences. Our agency specializes in delivering top-notch web development services tailored to meet the unique needs of businesses and individuals. With a mix of creativity and technical expertise, we transform your digital vision into reality."
        />
		<TextWidget
        text="Custom Web Development:
At Vishal Web Tech, we understand that no two businesses are the same. Our custom web development services ensure that your website is as unique as your brand. We focus on building responsive, user-friendly websites that engage visitors and drive conversions by implementing the latest technologies and design trends."
        />
		<TextWidget
        text="E-commerce Solutions:
In the digital age, an effective e-commerce platform is essential. Our team excels at creating secure, scalable, and easy-to-navigate e-commerce websites. We integrate advanced features such as payment gateways, shopping carts, and inventory management systems, ensuring a seamless shopping experience for your customers."
        />
		<TextWidget
        text="CMS Development:
Content is king, and managing it should be effortless. Vishal Web Tech offers robust CMS development services, enabling you to update and maintain your website with ease. We specialize in platforms like WordPress, Joomla, and Drupal, providing you with the tools to manage your content efficiently."
        />
		<TextWidget
        text="Web Application Development:
Our web application development services are designed to boost your business operations. We build powerful, database-driven applications that solve real-world problems. Whether you need a customer portal, a booking system, or any other custom application, our team can deliver solutions that enhance productivity."
        />
     
	  <TextWidget
        text="UI/UX Design:
A visually appealing website with an intuitive user interface is crucial for retaining visitors. Our designers prioritize user experience, creating interfaces that are not only beautiful but also highly functional. We focus on delivering seamless navigation and engaging interactions that keep users coming back."
        />

<TextWidget
        text="SEO and Digital Marketing:
Beyond development, achieving visibility in search engines is essential. We offer SEO and digital marketing solutions to ensure your website attracts the right audience. From keyword optimization to social media strategies, Vishal Web Tech helps propel your online presence to new heights."
        />
		
		<TextWidget
        text="Maintenance and Support:
Our commitment to your website doesn't end at launch. We provide ongoing maintenance and support to ensure your site remains updated and secure. Our team is always ready to assist with any updates, troubleshooting, or enhancements needed to keep your website operating smoothly."
        />
		
		<TextWidget
        text="At Vishal Web Tech, we pride ourselves on delivering high-quality web development services that align with your business objectives. Partner with us to take your digital presence to the next level, and let our experts turn your ideas into impactful online experiences."
        />
		
	
      </Div>
      <Spacing lg="90" md="70" />
	  <section id= "portfolioslider" >
     
	   </section>
      {/* End PortfolioSlider Section */}
        <Spacing lg='90' md='45'/>
      </Div>
     
      <Div className="container">
        <Cta 
          title='Let’s disscuse make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}
